import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

export default function NotFound() {
  return (
    <Layout>
      <div>
        <h1>404</h1>
        <h6>Sorry, this page doesn't exist</h6>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  `;